import Page from "../components/Page";
import PageProjects from "../components/PageProjects";
import SEO from "../components/SEO";

const ProjectsPage = (props: any) => {
  const { transitionStatus, uri } = props;

  return (
    <Page transitionStatus={transitionStatus} uri={uri}>
      <SEO title="Projects" />
      <PageProjects />
    </Page>
  );
};

export default ProjectsPage;
