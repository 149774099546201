/** @jsxImportSource theme-ui */
import { Fragment } from "react";
import ProjectsIndicator from "./ProjectsIndicator";
import SectionProject from "./SectionProject";
import { projectsInNavigation } from "../data";

const PageProjects = () => {
  return (
    <Fragment>
      {projectsInNavigation.map((project, i) => (
        <SectionProject key={project.id} index={i} project={project} />
      ))}
      <ProjectsIndicator colors={projectsInNavigation.map((p) => p.color)} />
    </Fragment>
  );
};

export default PageProjects;
